import React, { Component } from "react"
import { connect } from 'react-redux';
import axios from "axios";
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import Skip from '../../Shared/Skip'
import "../Create.css"
import "../../../../onepoint.css"

const checkForSkip = async (userName, jwt) => {
    const section = 'create' 
    const searchParams = `?userName=${userName}&sectionName=${section}`;
  
    return await axios.get(
      process.env.REACT_APP_JONDO_API +
      process.env.REACT_APP_CHECK_FOR_SKIP +
      searchParams,  {headers: {'Authorization': 'Bearer '+jwt}}
    );
};


const title = "OAuth 2.0"
const p1 = "Our web services include Order Placement, Cancel Order and Redo Order Placement.  All these services require a valid Access Token. Access Tokens are generated by submitting a POST request with  user credentials to the URL given below."
const p2 = "Once a valid access token has been obtained, we recommend you store it in a safe place and begin using it for placing, cancelling, or resubmitting orders by sending it in the header as a bearer token shown below."
const p3 = "Example: "
const p4 = "Authorization: Bearer 9b238596775fe9956f655d24a23693abb10ad411"
const p5 = "Please note that access tokens will expire within 24 hours and that by submitting a new token request, previously generated tokens are immediately expired."

class Request extends Component {
    

    constructor(props) {
        
        super(props);
    
        this.state = {
            skip: false                   
        }
      
    }

    // update the table when submit search form
    async componentDidMount() {
                  
        const { userName, jwt } = this.props;
        
        const response = await checkForSkip(userName, jwt)
               
        if (response.data) {    
            //console.log("response.data", response.data)          
            //console.log("response.data.isAllow", response.data.isAllow)
            this.setState({ skip: response.data.isAllow});
        }
               
    }

    componentDidUpdate() {
        window.scrollTo(0, 0);
    }
    
    render() {        
        //const skipButton = this.props.approved ? '' : (<Skip nextStage='cancel' score={25}/>);

        //console.log("this.state.skip", this.state.skip)
        const skipButton = (this.props.skipped || this.props.approved || !this.state.skip) ? '' : (<Skip nextStage='cancel' score={25}/>);
        
        var increasePercentage = this.props.asUser? 0 : 2 ;

         return ( 
            <div className="request pt-3">
                <h5><b>{title}</b></h5>
                <div className="reg-text">{p1}</div>
                
                {/*<hr className="my-4"/>*/}

                <h5><b>Where to Send Your Post Request</b></h5>
                <p className="request-url p-3">https://jondohd.com/jondoApi/generateToken.php</p>
                <br/><br/>

                <h5><b>Request Data</b></h5>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th className = "width100" >Data Type</th>
                            <th>Value Description</th>
                            <th>Required?</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>userId</td>
                            <td>Number (5)</td>
                            <td>Your user account ID will be provided to you and must be passed with every request</td>
                            <td>Y</td>
                        </tr>
                        <tr>
                            <td>apiKey</td>
                            <td>String (30)</td>
                            <td>Your api key will be provided to you and must be passed with each request so that your identity can be validated</td>
                            <td>Y</td>
                        </tr>
                    </tbody>
                </Table>
                <br></br>

                <div className="reg-text">{p2}</div>
                <div className="reg-text">{p3}</div>
                <div className="reg-text">{p4}</div>
                <div className="reg-text">{p5}</div>
                <br/><br/>

                <h5><b>Response Data</b></h5>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th className = "width100" >Data Type</th>
                            <th>Value Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>access_token</td>
                            <td>String (256)</td>
                            <td>Access Token string is used in header while submitting any request to the API</td>
                        </tr>
                        <tr>
                            <td>expires_in</td>
                            <td>Number (5)</td>
                            <td>Seconds value that represents the lifespan of the access token. Please note that expired token cannot be used for placing any requests.</td>
                        </tr>
                        <tr>
                            <td>token_type</td>
                            <td>String (32)</td>
                            <td>Bearer token type</td>
                        </tr>
                    </tbody>
                </Table>

                <div className="d-flex mt-5 mb-3 mt-auto" style={{justifyContent:"space-between"}}>
                    <Button
                        disabled
                        size="lg"
                        variant="outline-light"
                        onClick={() => this.props.handleNext('', 0) }
                    >
                    </Button>
                    <div style={{display:"inline-flex"}}>
                        <Button
                            size="lg"
                            variant="primary"
                            onClick={() => this.props.handleNext('sample_create', increasePercentage) }
                        >
                            NEXT
                        </Button>
                        {skipButton}
                    </div>
                </div>
             
            </div>
        );
    }
}


const mapStateToProps = state => (
    {
        userName: state.session.userName, 
        jwt: state.session.jwt
    }
);

export default connect(
    mapStateToProps
)(Request);
//export default Request;
