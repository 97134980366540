import React, { Component } from "react";
import { connect } from 'react-redux';
import logo from "../../images/OnePoint.png";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import "./Navbar.css";

class NavTop extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick = () => {
    this.props.handleLogin(false);

    //event.preventDefault();
  };

  render() {
    return (
      <Navbar id="nav-top">
         {this.props.level == 'basic'
              ? <Link to="/myaccount/info" >
                  <Navbar.Brand >
                    <img src={logo} alt="OnePoint" id="logo" />
                  </Navbar.Brand>
                </Link>
              : <Link to="/admin/list" >
                  <Navbar.Brand >
                    <img src={logo} alt="OnePoint" id="logo" />
                  </Navbar.Brand>
                </Link>
            }
         
          <Nav className="mx-auto">
            <Navbar.Text className="nav-text">
              Welcome, {this.props.company}
            </Navbar.Text>
          </Nav>
          <Nav className="text-right">
            
            {this.props.level == 'basic'
              ? <Link to="/myaccount/info" className="nav-text nav-link">
                  My Account
                </Link>
              : <Link to="/admin/list" className="nav-text nav-link">
                  My Account
                </Link>
            }
            
            <Navbar.Text className="nav-text"> | </Navbar.Text>
            <Link
              to="/"
              className="nav-text nav-link"
              onClick={this.handleClick}
            >
              Log Out
            </Link>
          </Nav>
      </Navbar>
    );
  }
}

const mapStateToProps = state => (
  {     
    level: state.session.level,
    
  }
);


//export default NavTop;
export default connect(
  mapStateToProps,
  null
)(NavTop);
