import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from "axios"
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import Skip from '../../Shared/Skip'
import "../../../../onepoint.css";

const title = `Status Update`
const p1 = `Anytime an item's status changes, our system will send a status update to the client. We will use the POST method to send the following XML payload to the URL the client has provided via account configuration or in the order request itself. Please note there are item statuses and order level statuses in the post. Item level statuses report the item's status at the time of posting. The order level statuses summarize the status of the order as whole.`

const p2_1 = `Accepted - Image validation has passed and the order is accepted for production`
const p2_1a = `Rejected - Image validation has failed and the order has been removed from the system. Images should be fixed and the order should be sent again`
const p2_2 = `Processing - Order / Item is in production`
const p2_3 = `Shipped - Order / Item is shipped`
const p2_4 = `Cancelled - Order / Item is cancelled from our side`
const p2_5 = `On Hold - Order / Item is held in production due to some issue`

const p3 = ``
const p4 = `To ensure that status updates are successfully received, responses to any status update post will be monitored.`
const p5 = `Please choose the type of acknowledgement from the following two options:`
const p6_1 = `HTTP Response – only the HTTP response code will be monitored. Any response code other than 200 will be considered as a failure and status updates will be retried. If a retry is not needed please respond with a HTTP 200.`
const p6_2 = `Status Update Acknowledgement – a response with an XML String indicating success or failure. Please refer to the samples.`
const p7 = `If no response is recorded within 60 seconds, the status update attempt will be treated as a failure.`
const p8 = `Failures will be re-attempted thrice before it is escalated via email.`
const p9 = `To utilize status updates, please provide a URL to post this data to.`


const checkForSkip = async (userName, jwt) => {
    const section = 'status' 
    const searchParams = `?userName=${userName}&sectionName=${section}`;
  
    return await axios.get(
      process.env.REACT_APP_JONDO_API +
      process.env.REACT_APP_CHECK_FOR_SKIP +
      searchParams,  {headers: {'Authorization': 'Bearer '+jwt}}
    )
}

class Request extends Component {
    
    constructor(props) {
        
        super(props);
    
        this.state = {
            skip: false                   
        }
      
    }

    // update the table when submit search form
    async componentDidMount() {
                  
        const { userName, jwt } = this.props;
        
        const response = await checkForSkip(userName, jwt)
               
        if (response.data) {    
            //console.log("response.data", response.data)          
            //console.log("response.data.isAllow", response.data.isAllow)
            this.setState({ skip: response.data.isAllow});
        }
               
    }



    componentDidUpdate() {
        window.scrollTo(0, 0);
    }

    render() {        
        
        //const skipButton = this.props.approved ? '' : (<Skip />);
        const skipButton = (this.props.skipped || this.props.approved || !this.state.skip) ? '' : (<Skip />);
        var increasePercentage = this.props.asUser? 0 : 2 ;
        
        return (
            <div className="request pt-3">
                <h5><b>{title}</b></h5>
                <div className="reg-text">{p1}</div>
                <ul>
                    <li>{p2_1}</li>
                    <li>{p2_1a}</li>
                    <li>{p2_2}</li>
                    <li>{p2_3}</li>
                    <li>{p2_4}</li>
                    <li>{p2_5}</li>
                </ul>

                <p>{p3}</p>

                <h5><b>Request Header</b></h5>

                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Value Description</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Content-Type</td>
                        <td>Used to indicate the original media type of the request. Our status update will always
                            be <b>application/xml</b></td>
                    </tr>
                    <tr>
                        <td>Content-Length</td>
                        <td>Indicates the size of the xml body in the request. Expressed in bytes</td>
                    </tr>
                    <tr>
                        <td>X-Api-Key</td>
                        <td>We will include your API key value in this header element</td>
                    </tr>
                    </tbody>
                </Table>

                <p>{p3}</p>

                <h5><b>Request Body</b></h5>

                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th className="width100">Data Type</th>
                        <th>Value Description</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>poNumber</td>
                        <td>String (50)</td>
                        <td>The purchase Order number you originally submitted with your order.</td>
                    </tr>
                    <tr>
                        <td>orderId</td>
                        <td>Number (12)</td>
                        <td>The unique Order Id# in the synergize system, returned after every successful order is
                            placed.
                        </td>
                    </tr>
                    <tr>
                        <td>orderStatus</td>
                        <td>String (15)</td>
                        <td>The status of the order. Status are driven when an item status changes that makes all item
                            status match or a single item breaks a status threshold. For example you can expect the
                            following:
                            <ul>
                                <li>Accepted - All items have been accepted.</li>
                                <li>Rejected - Any item on the order has been rejected.</li>
                                <li>Processing - First item on the order has entered the processing state when the order
                                    was in a different state.
                                </li>
                                <li>Shipped - All items on the order are either shipped or a mix of shipped and
                                    cancelled.
                                </li>
                                <li>Cancelled - All items on the order have been cancelled.</li>
                                <li>On Hold - All items on the order are on hold.</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>orderStatusDate</td>
                        <td>Date (ISO-8601 format)</td>
                        <td>Date is of ISO-8601 format with UTC timezone offset – YYYY-MM-DDThh:mm:ssTZD (Ex:
                            2017-03-21T22:09:01Z). This date represents the exact date and time the order status was
                            changed.
                        </td>
                    </tr>
                    <tr>
                        <td>orderStatusMessage</td>
                        <td>Text</td>
                        <td>If the order image is rejected here you will see the reason; otherwise the value will be
                            blank.
                        </td>
                    </tr>
                    <tr>
                        <td>items/item/id</td>
                        <td>Number (12)</td>
                        <td>The unique item ID#.</td>
                    </tr>
                    <tr>
                        <td>items/item/itemNumber</td>
                        <td>String (50)</td>
                        <td>This is the exact value that represents the itemNumber we received from you in the order
                            request
                            XML.
                        </td>
                    </tr>
                    <tr>
                        <td>items/item/status</td>
                        <td>String (15)</td>
                        <td>Indicates whether the item is Accepted, Rejected, Processing, Shipped, Cancelled or On
                            Hold.
                        </td>
                    </tr>
                    <tr>
                        <td>items/item/statusDate</td>
                        <td>Date (ISO-8601 format)</td>
                        <td>Date is of ISO-8601 format with UTC timezone offset – YYYY-MM-DDThh:mm:ssTZD (Ex:
                            2017-03-21T22:09:01Z). This date represents the exact date and time the item status was
                            changed.
                        </td>
                    </tr>
                    <tr>
                        <td>items/item/carrier</td>
                        <td>String (20)</td>
                        <td>If the item is in Shipped status, this value will be the carrier for the package that
                            contains the item
                            otherwise the value will be blank.
                        </td>
                    </tr>
                    <tr>
                        <td>items/item/tracking</td>
                        <td>Text</td>
                        <td>If the item is in Shipped status, tracking number will be populated in this field; otherwise
                            the
                            value will be blank.
                        </td>
                    </tr>
                    <tr>
                        <td>items/item/trackingUrl</td>
                        <td>Text</td>
                        <td>If the item is in Shipped status, this value will be the tracking link that can be used to
                            track the
                            package; otherwise the value will be blank.
                        </td>
                    </tr>
                    <tr>
                        <td>items/item/carrierSecondary</td>
                        <td>String (20)</td>
                        <td>If the item is in Shipped status, this value will be the secondary carrier for the package
                            that contains the item. If
                            not used, this will have a blank value.
                        </td>
                    </tr>
                    <tr>
                        <td>items/item/trackingSecondary</td>
                        <td>Text</td>
                        <td>If the item is in Shipped status, secondary tracking number will be populated in this field;
                            otherwise the
                            value will be blank.
                        </td>
                    </tr>
                    <tr>
                        <td>items/item/trackingUrlSecondary</td>
                        <td>Text</td>
                        <td>If the item is in Shipped status, this value will be the secondary tracking link that can be
                            used to track the
                            package; otherwise the value will be blank.
                        </td>
                    </tr>
                    <tr>
                        <td>items/item/location/city</td>
                        <td>String (15)</td>
                        <td>The city where your item was received, processed, or shipped.</td>
                    </tr>
                    <tr>
                        <td>items/item/location/zip</td>
                        <td>String (10)</td>
                        <td>The zip where your item was received, processed, or shipped.</td>
                    </tr>
                    <tr>
                        <td>items/item/location/state</td>
                        <td>String (20)</td>
                        <td>The state where your item was received, processed, or shipped.</td>
                    </tr>
                    <tr>
                        <td>items/item/location/country</td>
                        <td>String (ISO 3166-2 format)</td>
                        <td>The country, with ISO 3166-2 format, where your item was received, processed, or shipped.
                        </td>
                    </tr>
                    </tbody>
                </Table>

                <div className="reg-text">{p6_1}</div>
                <div className="reg-text">{p9}</div>

                <div className="d-flex mt-5 mb-3" style={{justifyContent: "space-between"}}>
                    <Button
                        disabled
                        size="lg"
                        variant="outline-light"
                        onClick={() => this.props.handleNext('', 0)}
                    >
                    </Button>
                    <div style={{display: "inline-flex"}}>
                        <Button size="lg" variant="primary"
                                onClick={() => this.props.handleNext('testPlan_status', increasePercentage)}>NEXT</Button>
                        {skipButton}
                    </div>
                </div>

            </div>
        );
    }
}


const mapStateToProps = state => (
    {
        userName: state.session.userName,
        jwt: state.session.jwt
    }
);

export default connect(
    mapStateToProps
)(Request);

//export default Request;
