import React, { Component } from "react";
import Card from "react-bootstrap/Card";
import logo from "../../images/OnePoint.png";

class CardTitle extends Component {
  render() {
    return (
      <Card.Title>
        <div className="text-center">
          <Card.Img className="my-5" src={logo} style={{ width: "70%" }} />
        </div>
        <h3 className="text-center mb-5 title">API INTEGRATION</h3>
      </Card.Title>
    );
  }
}

export default CardTitle;
