import React, { Component } from "react";
import axios from "axios";
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import { doToggleLoadClients } from '../../actions'
import { doSetOptions } from '../../actions'

import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import Button  from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Alert from "react-bootstrap/Alert";

import Footer from "../Misc/Footer";
import SortButton from "../Misc/SortButton";


//const clisntStatuses = ['SALES_APPROVED'];

const getClients = async (searchData, adminName, jwt) => {

  const { userName, clientStatus, page, limit, orderBy, descAsc = "desc", companyName } = searchData;

  //$clientStatus, $userName, $adminName, $page, $limit, $orderBy, $descAsc, $companyName
  const searchParams = `?userName=${userName}&companyName=${companyName}&clientStatus=${clientStatus}&page=${page}&limit=${limit}&orderBy=${orderBy}&descAsc=${descAsc}&adminName=${adminName}`;

  return await axios.get(
      process.env.REACT_APP_JONDO_API +
      process.env.REACT_APP_GET_CLIENTS +
      searchParams, {headers: {'Authorization': 'Bearer '+jwt}}
  );
};

class ClientsTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clients:[],
      page: 0,
      total: 0,
      limit: 10,
      orderBy: "id",
      isDataReady: false,
      error: "",
      dsc_userName: true,
      dsc_clientStatus: true,
      dsc_id: true,
      showModal:false,
      updateId:'',
      updateEmail:'',
      updatePassword:'',
      updateNewUserName:'',
      updateOldUserName:'',
      //updateClientStatus:'SALES_APPROVED'
      updateClientStatus:'',
      errorSubmit: false,
      loginAs: false
    };

    this.handleSort = this.handleSort.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handlePrevious = this.handlePrevious.bind(this);
    this.updateTable = this.updateTable.bind(this);
    
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleShowModal = this.handleShowModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    
  }

  async updateTable() {
    const descAsc = this.state["dsc_" + this.state.orderBy] ? "desc" : "asc";
    const extraOptions = { page: this.state.page, orderBy: this.state.orderBy, descAsc: descAsc }
    
    // if the request come from recent order page, just use the default options 
    //const searchOptions = this.props.type === "recent" ? extraOptions : { ...this.props.searchOptions, ...extraOptions };
    const searchOptions = {...this.props.searchOptions, ...extraOptions};
    const adminName = this.props.userName;
    const jwt = this.props.jwt;
    //alert('in update table: '+ searchOptions.userName+ ', ' +searchOptions.clientStatus)
    const response = await getClients( searchOptions, adminName, jwt);

    if (response.data.error) {
      this.setState({ error: response.data.error.text });
    } else {
        
      this.setState({ clients: response.data.clients, total: response.data.total, isDataReady: true });
    }
  }

  // update the table when submit search form
  componentDidUpdate() {
   
    const { loadNewData } = this.props;
    
    if (loadNewData) {
      
      this.props.onToggleLoadClientsFlag();
      this.setState({
        page: 0
      }, () => this.updateTable());

    }

  }

  // update the the table when first load
  async componentDidMount() {

    this.setState({ isDataReady: false });
    //const searchOptions = this.props.type === "recent" ? '' : { ...this.props.searchOptions };
    const searchOptions = this.props.searchOptions;
    const adminName = this.props.userName;
    const jwt = this.props.jwt;
    
    const response = await getClients( searchOptions, adminName, jwt);
    if (response.data.error) {
      this.setState({ error: response.data.error.text });
    } else {
      this.setState({
        clients: response.data.clients,
        total: response.data.total,
        isDataReady: true
      });
    }
  
  }

  // ***************************************** */
  // Function to handle events for Pagination  */
  // ***************************************** */
  handleClick(e) {
    window.scrollTo(0, 500);
    this.setState({
      page: e.target.id - 1
    }, () => this.updateTable());
  }

  handlePrevious(e) {
    window.scrollTo(0, 500);
    e.preventDefault();
    this.setState({
      page: parseInt(this.state.page) - 1
    }, () => this.updateTable());
  }

  handleNext(e) {
    window.scrollTo(0, 500);
    e.preventDefault();
    this.setState({
      page: parseInt(this.state.page) + 1
    }, () => this.updateTable());
  }
  // End of Pagination functions

  handleSort(e) {
    window.scrollTo(0, 500);
    e.preventDefault();
    this.setState({
      orderBy: e.target.id,
      ["dsc_" + e.target.id]: !this.state["dsc_" + e.target.id]
    }, () => this.updateTable());
  }

  handleSubmit = async event => {
    
    event.preventDefault();

    const userId = this.state.updateId;
    const email = this.state.updateEmail;
    //const password = this.state.updatePassword;
    //const newUserName = this.state.updateNewUserName;
    const userName = this.state.updateOldUserName;
    const clientStatus = this.state.updateClientStatus;


    if(clientStatus == ''){
      this.setState({ errorSubmit: true });
    }else{
          
      const response = await axios.post(
        process.env.REACT_APP_JONDO_API + process.env.REACT_APP_UPDATE_CLIENT_STATUS,
        /*{ userId, userName, newUserName, password, clientStatus, email }*/
        { userId, userName, clientStatus, email }
      );

      if (response.data.error) {
        this.setState({ error: response.data.error.text });
      } else {
          this.updateTable()
      }
      //alert('Client updated! \n\n An email was sent to '+newUserName+ ' with the new credentials.')
      this.handleCloseModal()
    }

  };

  handleCloseModal() {
    this.setState({ showModal: false, updateClientStatus:''});
  }

  handleShowModal(id, userName, email) {
    this.setState({ updateId: id, updateOldUserName: userName, updateEmail: email, showModal: true, errorSubmit: false });
  }

  async loginAs (userName){

    const { jwt } = this.props;

    const response = await axios.post(
      process.env.REACT_APP_JONDO_API + process.env.REACT_APP_LOGIN_AS,
      { loginAs:userName } , {headers: {'Authorization': 'Bearer '+jwt} }
    );
    
    if (response.data.error) {

      this.setState({ error: response.data.error.text });
    
    } else {
      
      if (this.state.rememberMe) {

        let d = new Date();
        d.setTime( d.getTime() + parseInt(response.data.JWTExpires)*1000 );
        const expires = "expires=" + d.toUTCString();
        const rememberMe = "rememberMe=" + this.state.rememberMe;
        const jwt = "jwt=" + response.data.jwt;
        document.cookie = rememberMe + ";" + expires + ";" + jwt + ";" + expires + ";";    
      }
      
      const {id, apiKey, phone, email, street, city, zip, state, country, shipUrl, level, userStatus, clientPortalStatuses, jwt, locations, order, request, status, 
        clientStatuses, company} = response.data;

      this.props.handleLogin(
        true,
        id,
        userName,
        apiKey,
        phone,
        email,
        street,
        city,
        zip,
        state,
        country,
        shipUrl,
        level,
        userStatus,
        //response.data.locations,
        //response.data.status,
        clientPortalStatuses,
        jwt,
        company,
        this.props.userName
      );
        
      const options = {locations, order, request, status, clientStatuses};
            
      this.props.setOptions(options);
      localStorage.setItem('options', JSON.stringify(options));
      this.setState({ loginAs: true });
    
    }

  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value, errorSubmit: false });
  };


  render() {
    
    
    console.log(this.props.clientStatuses);

    if (this.state.loginAs) {
      return (
        <Redirect
          to={{
            pathname: "/myaccount/info"
          }}
        />
      );
    }
    
    const { clients, isDataReady } = this.state;
    const pageCount = Math.ceil(parseInt(this.state.total) / this.props.searchOptions.limit);

    let errMsg;
    errMsg = this.state.errorSubmit && (
    <Alert variant = 'danger' >
        <ul>
         <li>Please select the Customer Status</li>
        </ul>        
      </Alert>
    );

    const header = (
      <thead>
        <tr>
          <th>User ID</th>
          <th>User Name &nbsp;
            <SortButton 
              id="userName" 
              handleSort={this.handleSort} 
              btnSort={this.state.dsc_poNumber ? "fas fa-caret-down" : "fas fa-caret-up"} 
            />
          </th>
          <th>Company</th>
          <th>Status &nbsp;
            <SortButton 
              id="clientStatus" 
              handleSort={this.handleSort} 
              btnSort={this.state.dsc_total ? "fas fa-caret-down" : "fas fa-caret-up"} 
            />
          </th>
          <th>Email</th>
          {/*<th>Phone</th>*/}
          <th>Integration %</th> 
          <th>Login As</th>
          {/*<th>Edit</th>*/}
        </tr>
      </thead>
    );

    if (!isDataReady) {
      return (
        <Table striped bordered hover>
          {header}
          <tbody>
            <tr>
              <td colSpan="8">Loading ...</td>
            </tr>
          </tbody>
        </Table>
      );
    }

    if (clients.length === 0) {
      return (
        <div className="OrdersTable">
          No records found
        </div>
      )
    }

    return (
      <div className="OrdersTable">
        
        <Modal show={this.state.showModal} onHide={this.handleCloseModal} size="lg">
          
            <Modal.Header closeButton>
                <Modal.Title>Update Customer Info</Modal.Title>
            </Modal.Header>
            
            <Form onSubmit={this.handleSubmit}>  

            <Modal.Body>
                {/*
                <Form.Group as={Row}>
                
                    <Form.Label column sm="2" className="text-right label">
                        User Name
                    </Form.Label>
                    <Col sm="9">
                        <Form.Control
                        className="input"
                        placeholder="User Name"
                        name="updateNewUserName"
                        onChange={this.handleChange}
                        />
                    </Col>
                </Form.Group>

                <Form.Group as={Row}>
                    <Form.Label column sm="2" className="text-right label">
                        Password
                    </Form.Label>
                    <Col sm="9">
                        <Form.Control
                        className="input"
                        placeholder="Password"
                        name="updatePassword"
                        onChange={this.handleChange}
                        />
                    </Col>
                </Form.Group>
                */}
                <Form.Group as={Row}>
                    <Form.Label column sm="2" className="text-right label">
                      Customer Status
                    </Form.Label>
                    <Col sm="9">
                        <Form.Control
                        className="input"
                        as="select"
                        name="updateClientStatus"
                        placeholder="Select One"
                        value={this.state.status}
                        onChange={this.handleChange}
                        >
                        <option>Select One</option>
                        {this.props.clientStatuses.filter(status => status== 'ENABLED' || status== 'DISABLED' || this.props.level == 'advanced')
                                                    .map(status => <option key={status}>{status}</option>)}

                        </Form.Control>
                    </Col>
                </Form.Group>

                {errMsg}
                
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={this.handleCloseModal}>
                Cancel
                </Button>
                <Button variant="primary" type="submit" >
                Update Changes
                </Button>
            </Modal.Footer>
            </Form>

        </Modal>
        
        <Table striped bordered hover>
          {header}
          <tbody>
            {clients.map(item => (
              <tr key={item.id}>
                <td>{item.id}</td>
                <td>{item.userName}</td>
                <td>{item.companyName}</td>
                <td>{item.clientStatus}</td>
                <td>{item.email}</td>
                {/*<td>{item.phone}</td>*/}
                <td>{item.integrationPercentage}</td>
                <td><Button variant="primary" onClick={() => this.loginAs(item.userName)} >Login As</Button></td>
                {/*
                {item.clientStatus == 'SALES_APPROVED ' || item.clientStatus == 'REGISTERED'
                  ? <td><Button variant="primary" onClick={() => this.handleShowModal(item.id, item.userName, item.email)} >Edit</Button></td>
                  : <td><Button variant="secondary" disabled>Edit</Button></td>
                }
              */}
              </tr>
            ))}
          </tbody>
        </Table>

        <Footer
          page={this.state.page}
          total={this.state.total}
          offset={this.props.searchOptions.limit}
          handleClick={this.handleClick}
          handleNext={this.handleNext}
          handlePrevious={this.handlePrevious}
          pageCount={pageCount}
        />

      </div>
    );
  }
}

const mapStateToProps = state => (
  { 
    searchOptions: state.searchClientsOption.searchOptions,
    userName: state.session.userName,
    level: state.session.level,
    jwt: state.session.jwt,
    loadNewData: state.searchClientsOption.loadNewData,
    clientStatuses: state.options.clientStatuses
  }
);

const mapDispatchToProps = dispatch => (
  {
    onToggleLoadClientsFlag: () => dispatch(doToggleLoadClients()),
    setOptions: options => dispatch(doSetOptions(options))
  }
);

//export default RecentOrders;
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientsTable);


