export const LOG_IN = 'LOG_IN';
export const LOG_OUT = 'LOG_OUT';

export const TOGGLE_LOAD_ORDERS = 'TOGGLE_LOAD_ORDERS';
export const UPDATE_SEARCH_ORDERS_OPTIONS = 'UPDATE_SEARCH_ORDERS_OPTIONS';

export const TOGGLE_LOAD_LOGS = 'TOGGLE_LOAD_LOGS';
export const UPDATE_SEARCH_LOGS_OPTIONS = 'UPDATE_SEARCH_LOGS_OPTIONS';

export const TOGGLE_LOAD_CLIENTS = 'TOGGLE_LOAD_CLIENTS';
export const UPDATE_SEARCH_CLIENTS_OPTIONS = 'UPDATE_SEARCH_CLIENTS_OPTIONS';

export const SET_OPTIONS = 'SET_OPTIONS';

export const NEXT = 'NEXT';
export const SKIP = 'SKIP';
export const SELECT = 'SELECT';
export const APPROVE = 'APPROVE';
export const START = 'START';
export const STAGE = 'STAGE';
export const GO_LIVE = 'GO_LIVE';
export const CLEAR = 'CLEAR';
export const UPDATE_FROM_DB = 'UPDATE_FROM_DB';
